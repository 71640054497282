export const nav_links = [
  {
    href: "aboutUs",
    title: "about_link",
  },
  {
    href: "workWith",
    title: "work_link",
  },
  {
    href: "etaps",
    title: "etaps_link",
  },
  {
    href: "form",
    title: "form_link",
  },
];
