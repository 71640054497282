export const etaps_info = [
  {
    number: 1,
    info: "etaps_p1",
    pos: "left",
  },
  {
    number: 2,
    info: "etaps_p2",
    pos: "right",
  },
  {
    number: 3,
    info: "etaps_p3",
    pos: "left",
  },
  {
    number: 4,
    info: "etaps_p4",
    pos: "right",
  },
  {
    number: 5,
    info: "etaps_p5",
    pos: "left",
  },
  {
    number: 6,
    info: "etaps_p6",
    pos: "right",
  },
];
